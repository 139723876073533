import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function AboutSection() {
    return (
        <div className="relative isolate overflow-hidden bg-black px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">

                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">About Us</h1>

                        </div>
                    </div>
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        src="https://cdn.discordapp.com/attachments/1004116212072067085/1156337171142291536/Blockchain_technology_concept_database_data_cryptocurrency_business_digital_finance_bitcoin_network_currency_money_background.jpg"
                        alt=""
                    />
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-300 lg:max-w-lg">
                            <p>
                                Our journey began with a vision - a vision to create a digital financial ecosystem that empowers individuals and businesses alike. The creator of Prosperium, a passionate and forward-thinking individual with extensive experience in blockchain technology and finance, embarked on this mission to revolutionize the way we perceive and utilize digital assets.

                                Driven by a deep understanding of the potential of blockchain and cryptocurrencies, our founder combined their technical expertise with an unwavering commitment to financial inclusivity. They envisioned a token that not only served as a secure and efficient means of digital exchange but also as an investment tool that could potentially reshape financial futures.
                            </p>

                            <p className="mt-8">
                                With a relentless pursuit of innovation, our founder assembled a team of like-minded individuals who shared this vision. Together, we have worked tirelessly to bring Prosperium to life. We believe that the future of finance lies in the hands of individuals, and Prosperium is our contribution to this democratized financial landscape.

                                Our commitment to transparency, security, and community-driven development sets Prosperium apart. We aim to empower our users with the tools and resources they need to navigate the complex world of digital finance confidently.
                            </p>
                            <p className="mt-6">
                                As we continue to evolve and expand, our dedication to fostering a thriving and inclusive Prosperium community remains unwavering. Join us on this exciting journey towards a brighter and more accessible financial future with Prosperium at its core.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
