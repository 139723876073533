
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './pages/Home';
import Whitepaper from './pages/Whitepaper';
import DownloadApp from './pages/DownloadApp';
function App() {
  return (
 <> <Router>
  
 <Routes>
   <Route exact path="/" element={<Home />} />
   <Route path="Whitepaper" element={<Whitepaper />} exact/>
   <Route path="Downloadapp" element={<DownloadApp />} exact/>
   {/* <Route path="about" element={<About />} exact/>
   <Route path="faqs" element={<Faq />} exact/> */}
 </Routes>
 
    

</Router></>
  );
}

export default App;
