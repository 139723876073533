import React, { useState } from "react";
import { Transition } from '@headlessui/react';
import { Link } from "react-scroll";

function Navbar({ scrollTo }) {
	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => {
	  setIsOpen(!isOpen);
	};
	return (
		<> 
	    <div>
      <nav className="shadow-sm sticky w-full z-10 bg-black font-nunito-sans">
        <div className="w-full">
          <div className="flex items-center h-20 w-full">
            <div className="flex items-center mr-20 ml-10 justify-between w-full">
              <div className="flex justify-center items-center flex-shrink-0">
                <img
                  src="/logo512.png"
                  alt="Logo"
                  width={70}
                  height={50}
                />
                <h1 className="font-bold px-2 text-xl">Prosperium</h1>
              </div>

              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <a
                    href="/"
                    className="cursor-pointer text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                    Home
                  </a>
                  <div
                  onClick={()=>scrollTo("tokenDetails")}
                    href="/faq"
                    className="cursor-pointer text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                      Prosperium(PPM)
                  </div>
                  <div
                         onClick={()=>scrollTo("aboutSection")}
                    className="cursor-pointer text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                    About
                  </div>
                  <div
                        onClick={()=>scrollTo("contactForm")}
                    className="cursor-pointer text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                    Contact Us
                  </div>     <a
                  href="/Whitepaper.pdf"
                  className="cursor-pointer text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                  Whitepaper
                </a>
                  <a
                 onClick={downloadApkFile}
                    className="cursor-pointer bg-red-500 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black"
                  >
                    Download App
                  </a>
                </div>
              </div>
            </div>
            <div className="mr-7 flex md:hidden">
              <button
                onClick={toggleNavbar}
                type="button"
                className="bg-white inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    color="#000000"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    color="#000000"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden z-1000" id="mobile-menu">
              <div
                ref={ref}
                className="bg-black px-2 pt-2 pb-3 space-y-1 sm:px-3 w-full"
              >
                <a
                  href="/"
                  className="cursor-pointer hover:bg-red-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Home
                </a>
                <div
         onClick={()=>scrollTo("tokenDetails")}
                  className="cursor-pointer hover:bg-red-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Prosperium(PPM)
                </div>
                <div
                   onClick={()=>scrollTo("tokenDetails")}
                  className="cursor-pointer hover:bg-red-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  About
                </div>
                <div
                   onClick={()=>scrollTo("tokenDetails")}
                  className="cursor-pointer hover:bg-red-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact Us
                </div>
                <a
                  href="/Whitepaper.pdf"
                  className="cursor-pointer hover:bg-red-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Whitepaper
                </a>
                <a
                  onClick={downloadApkFile}
                  className="cursor-pointer hover:bg-white bg-red-500 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Download App
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
		</>
	);
}

export default Navbar;


function downloadApkFile() {
  // Replace 'your_apk_file_url' with the actual URL of your APK file.
  const apkFileUrl = 'https://prosperium.in/prosperium-wallet.apk';

  // Create a hidden anchor element
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = apkFileUrl;
  anchor.download = 'prosperium.apk'; // Set the desired file name

  // Append the anchor to the document body
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor to start the download
  anchor.click();

  // Remove the anchor element from the document
  document.body.removeChild(anchor);
}