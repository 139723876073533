import AboutSection from '../components/aboutSection';
import ContactForm from '../components/contactForm';
import Footer from '../components/footer';
import HeroSection from '../components/heroSection';
import Navbar from '../components/navbar';
import React, { useEffect } from "react";
import TokenDetails from '../components/tokenDetails';

export default function Home() {
    // Create refs for the elements you want to scroll to
    const scrollToElement = (elementId) => {
        console.log(`tried to scroll: ${elementId}`);


        if (elementId === "tokenDetails") {
            window.scrollTo({
                top: window.screen.height,
                behavior: 'smooth',
            });/// Replace with the actual ID of the TokenDetails component
        } else if (elementId === "aboutSection") {
            window.scrollTo({
                top: window.screen.height * 2,
                behavior: 'smooth',
            });//  // Replace with the actual ID of the AboutSection component
        } else if (elementId === "contactForm") {
            window.scrollTo({
                top: window.screen.height * 3.5, behavior: 'smooth',
            });// Replace with the actual ID of the AboutSection component
        }
    };

    // Scroll to the element when the component mounts
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const scrollTo = urlParams.get("scrollTo");
        if (scrollTo === "tokenDetails") {
            scrollToElement("tokenDetails"); // Replace with the actual ID of the TokenDetails component
        } else if (scrollTo === "aboutSection") {
            scrollToElement("aboutSection"); // Replace with the actual ID of the AboutSection component
        }
    }, []);

    return (
        <>
            <Navbar
                scrollTo={(val) => scrollToElement(val)}

            />
            <HeroSection />
            <TokenDetails id="tokenDetails" />
            <AboutSection id="aboutSection" />
            <ContactForm id="contactForm" />
            <Footer />
        </>
    );
}
