import React from "react";

function HeroSection() {
  return (
    <section className="relative ">
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src="https://cdn.discordapp.com/attachments/1004116212072067085/1156314911505403945/black_-_13495_540p.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-black/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-black/95 sm:to-transparent"></div>

      <div className="flex flex-col md:flex-row lg:p-10 lg:pb-20 lg:pt-10">
        <div className="p-4 md:w-1/2 md:ml-auto relative">
          <div class="flex justify-center items-center h-full">
            <div class="flex flex-col items-start justify-center">
              <h1 class="text-4xl font-bold text-start tracking-tight lg:text-start text-white sm:text-6xl">
                Prosperium: Redefining Wealth in the Digital Age
              </h1>
              <p class="mt-6 text-lg leading-8 text-gray-400 text-start tracking-tight lg:text-start">
                Welcome to Prosperium, where we bridge the gap between transactions and investments. Our cryptocurrency token, PPM, empowers you to make secure payments and smart investments simultaneously. PPM: Shaping the future of digital wealth.
              </p>
              <div className="mt-5">  
                  {/* <a href="/Downloadapp" className="inline-block rounded-lg mr-3 bg-red-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1  hover:bg-white hover:ring-white hover:text-black">
                Download App
                <span className="text-indigo-200" aria-hidden="true">&rarr;</span>
              </a> */}



                <a href="/Whitepaper.pdf" className="inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white ring-1 ring-red-600 hover:ring-red-600">
                  Whitepaper
                  <span className="text-indigo-200" aria-hidden="true">&rarr;</span>
                </a></div>
            </div>
          </div>
        </div>
        <div className=" p-4 md:w-1/2 relative">
          <div className="max-w-xl text-center sm:text-left">
            <div className="rounded-md">
              <div className="rounded-lg px-3 py-3 lg:col-span-3 lg:px-6 lg:py-10">
                <img
                  src="/logo512.png"
                  alt="Logo"
                // width={70}
                // height={50}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;





