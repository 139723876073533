
const stats = [
  { name: 'Symbol', value: 'PPM' },
  { name: 'Total Supply', value: '25 Million' },
  { name: 'Circulating supply', value: '6 Million' },
  { name: 'Blockchain', value: 'Polygon(MATIC)' },
]

export default function TokenDetails() {
  return (
    <div  className="relative isolate overflow-hidden bg-black py-24 sm:py-32">
      <img
        src="https://cdn.discordapp.com/attachments/1004116212072067085/1156333480632909944/vecteezy_blue-technology-future-big-data-server-glow-triangular_7064604.jpg"
        alt=""
        className="absolute bg-black opacity-30 inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
     
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
      
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Prosperium(PPM) Details</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
          Prosperium is an ERC-20 token, a digital asset built on the Ethereum blockchain standard, and it is designed to function seamlessly within the Polygon (MATIC) blockchain ecosystem. This innovative combination harnesses the efficiency, low gas fees and scalability of Polygon while utilizing the widely recognized ERC-20 standard
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
       
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
